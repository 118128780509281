import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Text from '@/components/atoms/Text';
import styles from './index.module.scss';

const Chip = dynamic(() => import('./Chip'));

const Chips = ({ slugs, style, gender, simpleChip, hrefs }) =>
  (gender || slugs.length > 0) && (
    <div
      style={style}
      className={styles.chips}
    >
      {!simpleChip && <Text weight="bold">Estas viendo:</Text>}
      {gender && <Chip gender={gender} />}
      {slugs.map((slug, i) => (
        <Chip
          key={slug}
          href={hrefs[i]}
          slug={slug}
          simpleChip={simpleChip}
        />
      ))}
    </div>
  );
Chips.propTypes = {
  slugs: PropTypes.array,
  style: PropTypes.object,
  gender: PropTypes.string,
  simpleChip: PropTypes.bool,
  hrefs: PropTypes.array
};
Chips.defaultProps = {
  slugs: [],
  gender: '',
  style: {},
  simpleChip: false,
  hrefs: []
};
export default Chips;
